import { lazy, Suspense, useRef } from "react";
import { Switch, Route } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import routes from "./config";
import { Styles } from "../styles/styles";
import { forwardRef } from "react";
import { Controller, Scene } from "react-scrollmagic";
const Sequence = lazy(() => import("../pages/Home/Sequence/sequence")); 
const Router = () => {
  const ref = useRef<any>();

  const SequenceWithRef = forwardRef<HTMLDivElement, { progress: number }>(({ progress, ...rest }, ref) => (
    <Sequence ref={ref} progress={progress} {...rest} />
  ));
  return (
    <Suspense fallback={null}>
      <Styles />
      {/* <div className="App" style={{ backgroundColor: "black" }}>
      <Controller>
        <Scene duration="1000%" triggerHook="onLeave" pin>
          {(progress: number) => (
            <div style={{ height: "100vh", position: "relative" }}>
              <SequenceWithRef ref={ref} progress={progress} />
            </div>
          )}
        </Scene>
      </Controller>
    </div> */}
      <Header />
      <Switch>
        {routes.map((routeItem) => {
          return (
            <Route
              key={routeItem.component}
              path={routeItem.path}
              exact={routeItem.exact}
              component={lazy(() => import(`../pages/${routeItem.component}`))}
            />
          );
        })}
      </Switch>
      {/* <Footer /> */}
    </Suspense>
  );
};

export default Router;
